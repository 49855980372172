//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import flick from '@/mixins/Flickity.js'
var striptags = require('striptags')

export default {
  props: {
    cursos: Array,
    titulo: String,
    doFlick: Boolean,
    tipo: String,
    subtitulo: String,
  },
  mixins: [flick],

  computed: {
    itemsCount() {
      return this.cursos.length
    },
  },
  methods: {
    strip: (text) => striptags(text),
  },
}
