
const flick = {
  data() {
    return {
      loaded: false,
      // ableToFlick: false,
      flicker: {}
    }
  },
  computed: {
    id: (v) => v._uid,
    ableToFlick() {
      if(typeof window === undefined || typeof window === 'undefined') return
      if (innerWidth > 1904) {
        return this.itemsCount > 4
      }else if (innerWidth > 1264) {
        return this.itemsCount > 3
      } else if (innerWidth > 960) {
        return this.itemsCount > 2
      } else {
        return this.itemsCount > 1
      }
    }
  },
  methods: {
    makeFlickity() {
      let element = document.querySelector('.flickity-mobile-' + this.id);
      if(element !== null){
        this.flicker = new Flickity('.flickity-mobile-' + this.id, {
          wrapAround: true,
          cellAlign: 'left',
          groupCells: true,
          ...this.options
          
        })
      }
    },

    setHeight() {
      document.querySelectorAll('.flickity-slider .flex').forEach(element => {
        element.style.height = '100%'
      })
    },
    start(){
      this.makeFlickity()
      setTimeout(() => {
        this.setHeight()
      }, 500);
      this.carrousselLoaded = true
      this.loaded = true
      
    },

    checkAvailableToFLick() {
    setTimeout(() => {
      if (this.ableToFlick && this.doFlick) {
        this.start()
        setTimeout(() => {
          this.start()
        }, 1000);
      } else {
        this.loaded = true
      }
    }, 300);
    }
      
  },

  watch: {
    $route() {
      if (Object.values(this.flicker).length) {
        this.flicker.destroy()
        this.flicker = {}
      }
      this.loaded = false
      this.checkAvailableToFLick()

    }
  },
  mounted() {
    this.checkAvailableToFLick();
    
    addEventListener('resize', () => {
      this.checkAvailableToFLick();
    })
    
  },
}
export default flick;